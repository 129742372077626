import axios from 'axios'

import {
	message,
	Modal
} from 'ant-design-vue';

import storage from '@/utils/storage'
import store from '@/store'
import bus from '@/utils/bus.js'
const api = axios.create({
	baseURL: '/api',
	timeout: 15000
})

api.interceptors.request.use(async config => {
	config.headers.webClientType = 'web'
	const token = storage.get('token')
	if (token) {
		config.headers['authorization'] = token

	}
	config.headers['Content-Type'] = "application/json"
	config.headers['referer'] = location.href
	console.info('Api.config:', config)
	return config
})

api.interceptors.response.use((response = {}) => {
	console.info('Api.success:', response)
	let responseData = response && response.data || {}
	if (responseData.code == 200) {
		return responseData
	} else if (responseData.code == 401) {
		storage.clear()
		store.dispatch('setToken', '')
		// location.replace('/login')
		bus.$emit('login');
		return Promise.reject(false)
	} else if(responseData.code == 505){
		message.error(responseData.msg)
		return Promise.reject(false)
	}else {
		message.error(responseData.msg)
		return Promise.reject(false)
	}
}, error => {
	console.info('Api.error:', error)
	if (error.response && error.response.status == 401) {
		storage.clear()
		store.dispatch('setToken', '')
		location.replace('/login')
		return Promise.reject(false)
	}
	if (/timeout/.test(error.message)) {
		return Modal.warning({
			content: '请求超时，请检查网络'
		})
	}
	Modal.warning({
		content: error.message || '系统异常，请联系管理员'
	})
	return false
})

export default api
